/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  width: 80px !important;
  height: 36px !important;
  margin-bottom: 0px !important;
}
input[type="text"] {
  -moz-appearance: textfield;

  margin-bottom: 0px !important;
}
input[type="itemText"] {
  -moz-appearance: textfield;
}
.date-align {
  -moz-appearance: textfield;
}
.div-align-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.osc-title {
  color: #C3F7C8;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: xx-large;
}
.epic-display-4{
  font-size: xx-large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-stretch: expanded;
  font-weight: bolder;
}
.user-dp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C3F7C8;
  height: 45px;
  width: 45px;
  border-radius: 50px;
  font-size: 25px;
  line-height: 34px;
  margin-right: 15px;
  margin-bottom: 20px;
}
.nike-logo-image {
  height: 80px;
}
.logout-container {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 5px;
  shape-outside: -moz-linear-gradient();
  background-color: rgb(190, 178, 178);
  border-radius: 50px;
}
.user-name {
  margin-right: 10px;
  color: bisque;
}
.div-align-center-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.margin-left-neg-1px {
  margin-left: -1px;
}
.div-align-right {
  display: flex !important;
  align-items: right !important;
  justify-content: right !important;
}

.td-compact-table-cell-padding {
  padding: 8px 16px 8px 8px !important;
  min-width: 125px !important;
  max-width: 125px !important;
  vertical-align: middle;
}
.td-compact-table-cell-icon {
  padding: 8px 16px 8px 8px !important;
  min-width: 185px !important;
  max-width: 185px !important;
  align-items: right !important;
}

.isTableLoading {
  background-color: er-black !important;
  color: er-white !important;
}
.header {
  width: 100%;
  background-color: #656a73;
  color: black;
  text-align: center;
}

.footer {
  position: fixed;
  z-index: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25px;
  margin-top: -40px;
  clear: both;
  background-color: #191919;
  color: white;
  text-align: center;
}

.component-main {
  overflow: auto;
  padding-bottom: 40px;
}

.thead {
  background-color: #d1d1d1 !important;
  color: black;
  border-radius: 0 !important;
}

.bg-color-tabs {
  background-color: #f0f0f0;
}
.padding-tabs {
  padding-top: 5px;
}
.bg-color {
  background-color: #f7f7f7;
}
.icon-size {
  width: 80px;
  height: 80px;
}

.export-button-icon-size {
  padding-right: 3.67rem !important;
  padding-left: 3rem !important;
}

.flex-parent {
  display: flex;
}
.jc-center {
  justify-content: center;
}
button.margin-right {
  margin-right: 20px;
}
.ag-row .ag-cell {
  display: flex;

  align-items: center;
}
.inner-border {
  outline: 2px solid #ccc;
  border: 1px solid #999;
}
.button {
  word-wrap: break-word;
}
.center {
  margin: auto;
  width: 310px;
  padding-top: 115px;
}
.center-child {
  margin: auto;
  width: 600px;
  padding-top: 5px;
}
.center .nds-pull-quote__text-component {
  font-size: 18px !important;
}
.dc {
  position: absolute;
  left: 0px;
  width: 200px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
}

/* filterPanal style*/
.filter-field-container {
  height: 70px;
}
.custom-dropdown-container{
  border-radius: 150px;
}
.upload-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1px;
  margin-bottom: 15px;
}
.file-upload-container{
  margin-right:5px;
  margin-top: 10px;
  margin-left: 3px;
  margin-bottom: 15px;
align-items: right;
}
.button-container{
  display: flex;
  align-items: center;
}
.custom-button{
  display: flex;
  border-radius: 30px;
  padding: 12px 60px;
  font-size: 20px;
  background-color: #95e295;
}
.custom-button button:not(:disabled) {
  background-color: #95e295;
}

/* Style for the "Continue" button when hovered and enabled */
.custom-button button:not(:disabled):hover {
  background-color: #95e295;
}

/* Style for the "Continue" button when disabled */
.custom-button button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.justify-content-center {
  justify-content: center;
  align-items: center;
}
.input-text-align {
  position: absolute;
  left:0px;
}
.justify-content-space-around {
  justify-content: space-around;
  align-items: center;
}

.filter-fields-search-button .primary{
  background-color: #6DB1FF;
}

.margin-right-10px {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.margin-right-20px {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 50px;
  border-radius: 10px;
}
.erroMessage {
  font-size: 20px !important;
  font-family:Georgia, 'Times New Roman', Times, serif;
  padding-top: 50px;
  color: #df0b0b;
  font-weight:bold;
  justify-content: center;
}


/* Textinput styles */
.text-field-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 40px !important;
}

.text-field-label {
  top: 8px !important;
}

.text-field-container {
  min-height: 40px !important;
  margin: 10px;
}

.text-field-container  .nds-input-layout-control {
  height: 40px !important;
}

.caret-down-color {
  color: #6DB1FF;
}

.link-text-style {
  color: #6DB1FF !important;
}

.filter-fields-hr-style {
  margin: 0px;
  padding: 0px;
}

.advance-filter-field-container .nds-dropdown-container {
  min-height: 40px !important;
  margin: 0px 10px;
}

.advance-filter-field-container .nds-select-layout-control {
  height: 40px !important;
}

.advance-filter-field-container .nds-dropdown-label {
  top: 8px !important;
}
.advance-filter-field-container .nds-dropdown-select-container {
  height: 80px !important;
}

.advance-filter-field-container fieldset {
  height: 40px !important;
}

.advance-filter-row2 {
  margin: 0px 100px;
}

.advance-filter-row3 {
  margin: 10px;
}

.action-component-btn  .primary{
  background-color: #6DB1FF;
  min-width: 100px;
  color: black;
  background-image: linear-gradient(270deg, #6DB1FF, darkseagreen);
}


.modal-class {
  margin: 20px;
  background-color: #d80909;
}

.modal-base-class {
  padding: 10px;
  height: 600px;
  max-height: calc(100% - 60px) !important;
  border-radius: 10px !important;
}
.pp-modal-base-class {
  padding: 10px;
  height: 350px;
  max-height: calc(100% - 60px) !important;
  border-radius: 10px !important;
}

.modal-header-class {
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.modal-title-class {
  font-size: 20px;
}

.margin-left-10px {
  margin-left: 10px;
}
#modal-root section {
  padding: 1px;
  max-height: calc(100vh - 60px) !important;
}

#modal-root section::before {
  z-index: 0;
  background: transparent
}

.modal-field-container .nds-dropdown-container {
  min-height: 10px !important;
  margin: 5px 10px;
}

.modal-field-container .nds-select-layout-control {
  height: 40px !important;
  width: 300px !important;
  border-radius: 50px !important;
}

.modal-field-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50px;
}
.flex-container{
  display: flex;
  justify-content: center;
}
.flex-container >* {
  margin-right: 10px;
}
.button-container{
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.button-container>*{
  margin-right: 10px;
}
.modal-field-container .nds-dropdown-label {
  top: 8px !important;
}
.modal-field-container .nds-dropdown-select-container {
  height: 40px !important;
  border-radius: 50px;
}
.container{
  border-radius: 50px;
}
.modal-field-container fieldset {
  height: 40px !important;
}
.notification-success{
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  display: flex;
}
.notification-fail{
  position:fixed;
  bottom: 20px;
  right: 20px;
  background-color: #e33513;
  color: white;
  padding: 15px;
  border-radius: 5px;
  display: flex;
}

.rule-success-notification {
  margin-left: 10px;
  margin-top: 5px;
  background: #95e295;
  margin-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.rule-error-notification {
  margin-left: 10px;
  margin-top: 5px;
  background: #fa3f3f;
  margin-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.tms-dropdown-select-tag {
  position: relative;
  top:26px;
  left:140px
}

.div-login-details-child {
  margin-top: -20px;
}

.tms-dropdown-select-tag .value-display{
  display:none
}

.tms-dropdown-select-tag .nds-dropdown-select-container{
  width: 100%;
  border: 2px  white;
  border-radius: 5px;
}
.action-comp-label-pp-route{
  margin-top: 10px;
}
.pp-modal-error-tag{
  text-align: center;
  margin-top: 30px;
  color: #d80909;
}
action-component-btn {
  margin: 5px 0;
}

.action-component-btn button {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  background-color: #d1d1d1;
  color: #ffffff;
}

/* Style for the "Continue" button when enabled (not disabled) */
.action-component-btn button:not(:disabled) {
  background-color: #6db1ff;
  background-image: linear-gradient(270deg, #6db1ff, darkseagreen);
}

/* Style for the "Continue" button when hovered and enabled */
.action-component-btn button:not(:disabled):hover {
  background-color: #5a9dd2;
}

/* Style for the "Continue" button when disabled */
.action-component-btn button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #bab2b2;
  color: #767171;
}